import keyMirror from "keymirror";

export const InputType = keyMirror({
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  SINGLE: "single",
  MULTI: "multi",
});

export const exportFilterOption = [
  { value: "name", label: "Name", type: InputType.TEXT },
  { value: "industry", label: "Industry", type: InputType.SINGLE },
  { value: "vertical", label: "Vertical", type: InputType.SINGLE },
  { value: "classification", label: "Classification", type: InputType.SINGLE },
  { value: "ranking", label: "Ranking", type: InputType.SINGLE },
];
