import React from "react";

import PropTypes from "prop-types";
import { BasicInformation } from "./BasicInformation";
import {
  Conditional,
  LoadingSpinner,
  IconButton,
  IconLinkButton,
  BootstrapModal,
} from "components";
import { useToggle, useFetch } from "hooks";

export const ContactInformation = ({ contact_id, linked_in }) => {
  const [visible, toggle] = useToggle();
  const { data, isLoading, errorMessage, refetch } = useFetch({
    initialUrl: `/contact-information/${contact_id}`,
    skip: true,
  });

  const handleOpenModal = () => {
    toggle();
    refetch();
  };

  return (
    <>
      <IconButton
        className="icon-button ion-information-circled me-3"
        onClick={handleOpenModal}
      />
      <IconLinkButton
        href={linked_in}
        target="_blank"
        rel="noreferrer"
        className="icon-button ion-person-stalker"
      />

      <BootstrapModal size="xl" isOpen={visible} toggle={toggle}>
        <BootstrapModal.Header>Contact Information</BootstrapModal.Header>
        <BootstrapModal.Body>
          <Conditional if={isLoading}>
            <LoadingSpinner label="Candidate" size={100} />
          </Conditional>
          <Conditional if={errorMessage}>
            <h3>{errorMessage}</h3>
          </Conditional>
          {!errorMessage && !isLoading && (
            <BasicInformation data={data} linked_in={linked_in} />
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer toggle={toggle}>
          <a
            href={`https://www.itap-platform.com/contacts/${contact_id}/${data?.name}/information`}
            className="btn btn-primary pull-right mg-t-10"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-user-edit"></i> Edit Contact
          </a>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

ContactInformation.propTypes = {
  contact_id: PropTypes.string,
  linked_in: PropTypes.string,
};
