import React from "react";

import { getFormattedDate } from "utils";
import { AnimatedSmartTable } from "components";

const CampaignHistoryDisplay = ({ data = [] }) => {
  const columns = [
    {
      Header: "Date Sent",
      accessor: "date_sent",
      disableFilters: true,
      Cell: ({ row: { original } }) => {
        let dt = original.date_sent
          ? getFormattedDate({
            dateObject: original.date_sent,
            format: "DD MMM YYYY",
          })
          : "-";
        return dt;
      },
    },
    {
      Header: "Campaign",
      accessor: "campaign",
      disableFilters: true,
    },
    {
      Header: "Receiver",
      accessor: "receiver",
      disableFilters: true,
    },
    {
      Header: "Response Type",
      accessor: "response_type",
      disableFilters: true,
    },
    {
      Header: "Response",
      accessor: "response",
      disableFilters: true,
    },
  ];

  return <AnimatedSmartTable data={data} columns={columns} paginated />;
};

export { CampaignHistoryDisplay };
