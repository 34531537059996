import React, { useContext } from "react";

import { Pagination as PaginationComponent } from "components";
import { FetchContext } from "context";

export const Pagination = ({ sizeOption = [10, 20, 30, 40, 50, 100] }) => {
  const {
    data,
    error,
    totalCount,
    setPageSize,
    currentPage,
    setCurrentPage,
    pageSize,
  } = useContext(FetchContext);

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div style={{ textAlign: "left" }}>
      {data?.length > 0 && !error && (
        <>
          {"Items per page: "}
          <select onChange={handlePageSizeChange} value={pageSize}>
            {sizeOption.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
          <PaginationComponent
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      )}
    </div>
  );
};
