import ReactDOM from "react-dom";
import React from "react";
import { Row, Col, Spinner } from "reactstrap";

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: "9999",
};
const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "50px",
  zIndex: "99999",
};

export const LoadingOverlay = ({ active, children }) => {
  if (!active) return null;

  return ReactDOM.createPortal(
    <>
      <div style={overlayStyle} />
      <div style={modalStyle}>
        <Row>
          <Col className="text-center">
            <Spinner
              style={{ width: "2rem", height: "2rem" }}
              color="dark"
              type="grow"
            />
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              color="dark"
              type="grow"
            />
            <Spinner
              style={{ width: "4rem", height: "4rem" }}
              color="dark"
              type="grow"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{children}</h3>
          </Col>
        </Row>
      </div>
    </>,
    document.getElementById("modal-root")
  );
};
