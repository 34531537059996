import React, { useState } from "react";
import { Tooltip } from "reactstrap";

export const HoverTooltip = ({ children, hoverText, placement, id }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div id={"TooltipTarget" + id}>{children}</div>
      {hoverText && (
        <Tooltip
          placement={placement}
          isOpen={tooltipOpen}
          target={"TooltipTarget" + id}
          toggle={toggle}
        >
          {hoverText}
        </Tooltip>
      )}
    </>
  );
};
