import React from "react";

import { FetchProvider } from "context";

import { OrganizationDisplay } from "./OrganizationDisplay";

const OrganizationMain = () => {
  return (
    <>
      <div className="mb-5">
        <FetchProvider url={`/target/organization/in-asia`}>
          <h2 className="d-flex justify-content-start">In Asia</h2>
          <FetchProvider.LoaderOverlay />
          <FetchProvider.Error />

          <OrganizationDisplay />

          <FetchProvider.Pagination />
        </FetchProvider>
      </div>
      <div className="mb-5">
        <FetchProvider url={`/target/organization/not-in-asia`}>
          <h2 className="d-flex justify-content-start">Not in Asia</h2>
          <FetchProvider.LoaderOverlay />
          <FetchProvider.Error />

          <OrganizationDisplay />

          <FetchProvider.Pagination />
        </FetchProvider>
      </div>
    </>
  );
};

export { OrganizationMain };
