import React, { useContext, useState } from "react";

import { Form, Button } from "reactstrap";
import { toastr } from "react-redux-toastr";

import { BootstrapModal } from "components";
import { FetchContext } from "context";
import { useToggle, useAxiosPrivate, useLoading } from "hooks";
import { ExportFilter } from "./ExportFilter";

export const ExportOrg = () => {
  const authAxios = useAxiosPrivate();
  const [visible, toggle] = useToggle();
  const { retrieveData } = useContext(FetchContext);
  const [dynamicInput, setDynamicInput] = useState([{ inputType: "" }]);

  const addField = () => {
    const values = [...dynamicInput];
    values.push({ inputType: "" });
    setDynamicInput(values);
  };

  const removeField = (index) => {
    const values = [...dynamicInput];
    values.splice(index, 1);
    setDynamicInput(values);
  };

  const onChange = (index, name, e) => {
    const values = [...dynamicInput];
    values[index][name] = e ? e : "";
    setDynamicInput(values);
  };

  const onTypeSelect = (index, event) => {
    const values = [...dynamicInput];
    values[index]["inputType"] = event;
    values[index]["data"] = "";
    setDynamicInput(values);
  };

  const fetchSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await authAxios.post(`/organization/export`, {
        request_filters: dynamicInput,
      });
      const result = response.data;
      setDynamicInput([]);
      retrieveData();
      toggle();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  const [handleSubmit, isLoading] = useLoading(fetchSubmit);

  return (
    <>
      <Button className="mt-1" color="primary" onClick={toggle}>
        <i className="fas fa-users" /> Export
      </Button>
      <BootstrapModal size="lg" isOpen={visible} toggle={toggle}>
        <BootstrapModal.Header>Export</BootstrapModal.Header>
        <BootstrapModal.Body>
          <Form id="ExportOrg" onSubmit={handleSubmit}>
            <ExportFilter
              data={dynamicInput}
              onAddFields={addField}
              onRemoveFields={removeField}
              onChange={onChange}
              onTypeSelect={onTypeSelect}
            />
          </Form>
          {/* <pre>
            {JSON.stringify(
              {
                request_filters: dynamicInput,
              },
              null,
              2
            )}
          </pre> */}
        </BootstrapModal.Body>
        <BootstrapModal.Footer toggle={toggle}>
          <Button color="primary" onClick={addField}>
            Add Filter
          </Button>
          <Button
            disabled={!dynamicInput.length > 0 || isLoading}
            type="submit"
            form="ExportOrg"
            color="success"
          >
            {!isLoading ? "Submit" : "Processing..."}
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};
