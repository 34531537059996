import React from "react";

import ReactDatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";
import {
  Collapse,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useAxiosPrivate, useToggle, useCustomForm } from "hooks";
import { Button, SelectWrap } from "components";
import TimePicker from "react-time-picker";
import { stagesString } from "constants";

const StageUpdateAdd = ({ pipeline_id, retrieveData }) => {
  const initialState = {
    date: new Date(),
    stage: "",
    time: new Date().toLocaleTimeString(),
    representative: "",
  };

  const authAxios = useAxiosPrivate();
  const [visible, toggle] = useToggle();

  const { formData, handleOnChange, handleSubmit, reset } = useCustomForm(
    initialState,
    (e) => onSubmit(e)
  );

  const onSubmit = async (data) => {
    try {
      const response = await authAxios.post(
        `/target/pipeline/${pipeline_id}/update-stage`,
        {
          ...data,
        }
      );
      const result = response.data;
      reset();
      toggle();
      retrieveData();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-end">
        <Button color="primary" onClick={toggle}>
          Add
        </Button>
      </div>

      <Collapse isOpen={visible}>
        <Card>
          <CardBody className="text-start">
            <Form id="StageUpdateAdd" onSubmit={handleSubmit}>
              <FormGroup>
                <Label className="fw-bold me-3">Date</Label>
                <ReactDatePicker
                  selected={formData.date}
                  onChange={(event) => handleOnChange("date", event)}
                  dateFormat="MM/dd/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </FormGroup>
              <FormGroup>
                <Label className="fw-bold me-3">Meeting Time</Label>
                <TimePicker
                  id="time"
                  name="time"
                  value={formData.time}
                  onChange={(event) => {
                    handleOnChange("time", event.target.value);
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label> Stage </Label>
                <SelectWrap
                  id="stage"
                  name="stage"
                  value={formData.stage || ""}
                  options={stagesString}
                  onChange={(event) => handleOnChange("stage", event)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Representative </Label>
                <Input
                  id="representative"
                  type="text"
                  name="representative"
                  value={formData.representative || ""}
                  onChange={(event) =>
                    handleOnChange("representative", event.target.value)
                  }
                  required
                />
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Button type="submit" form="ReachOutHistoryAdd" color="success">
                  Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};

export { StageUpdateAdd };
