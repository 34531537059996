/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext, useRef } from "react";

import { useAxiosPrivate, useLoading } from "hooks";
import {
  ErrorHandler,
  LoaderSpinner,
  LoaderOverlay,
  Pagination,
} from "common/ServerFetch";
import { trueTypeOf } from "utils";

const FetchContext = createContext(null);
const { Provider } = FetchContext;

const FetchProvider = ({ url: initialUrl, skip = false, children }) => {
  const controller = new AbortController();
  const [url, updateUrl] = useState(initialUrl);

  const isFirstRun = useRef(skip);
  const authAxios = useAxiosPrivate();
  //DATA TABLE
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(50);

  //SORTING
  const [sort, setSort] = useState({
    direction: "none",
    accessor: "",
  });

  //FILTER
  const [search, setSearch] = useState({});

  const getRequestParams = () => {
    const filters = {};
    for (const key in search) {
      switch (trueTypeOf(search[key])) {
        case "array":
          const arr = [];
          search[key].map((e) => arr.push(e.value));
          filters[key] = arr;
          continue;
        case "object":
          filters[key] = search[key]["value"];
          continue;
        case "number":
        case "string":
        case "boolean":
        case "date":
          filters[key] = search[key];
          continue;
        default:
          break;
      }
    }

    let params = {
      ...filters,
      ...sort,
      page: currentPage,
      size: pageSize,
    };

    //convert object into a query string format
    const queryString = Object.keys(params)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&");
    return queryString;
  };
  const fetchData = async () => {
    setError("");
    const params = getRequestParams();
    try {
      const response = await authAxios.get(`${url}?${params}`, {
        signal: controller.signal,
      });
      const result = response.data;
      result?.totalCount && setTotalCount(result.totalCount);
      result?.content && setData(result.content);
    } catch (error) {
      setError(error.message);
    }
  };

  const [getData, isLoading] = useLoading(fetchData);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    updateUrl(initialUrl);
    getData();

    return () => {
      controller.abort();
    };
  }, [initialUrl, currentPage, pageSize, sort]);

  const value = {
    data,
    setData,
    isLoading,
    error,
    retrieveData: getData,
    totalCount,

    search,
    setSearch,

    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,

    sort,
    setSort,
  };

  return <Provider value={value}>{children}</Provider>;
};

FetchProvider.Error = ErrorHandler;
FetchProvider.LoaderOverlay = LoaderOverlay;
FetchProvider.LoaderSpinner = LoaderSpinner;
FetchProvider.Pagination = Pagination;
export { FetchProvider, FetchContext };
