import React from "react";

import { Row, Col } from "reactstrap";

import { FetchProvider } from "context";

import { CampaignAdd, CampaignDisplay } from "features/Campaigns";

const Campaign = () => {
  return (
    <FetchProvider url={`/campaign`}>
      <Row>
        <Col>
          <h1 className="d-flex justify-content-start">Campaigns</h1>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <CampaignAdd />
          </div>
        </Col>
      </Row>
      
      <FetchProvider.LoaderOverlay />
      <FetchProvider.Error />

      <CampaignDisplay />
    </FetchProvider>
  );
};

export default Campaign;
