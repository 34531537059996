import React, { useContext } from "react";

import { Form } from "reactstrap";
import { toastr } from "react-redux-toastr";

import { BootstrapModal, Button } from "components";
import { useToggle, useCustomForm, useAxiosPrivate } from "hooks";
import { FetchContext } from "context";

import { CampaignForm } from "./CampaignForm";

const CampaignAdd = () => {
  const initialState = {
    campaign_name: "",
    campaign_description: "",
  };
  const { retrieveData } = useContext(FetchContext);
  const [visible, toggle] = useToggle();
  const authAxios = useAxiosPrivate();

  const { formData, handleOnChange, handleSubmit, reset } = useCustomForm(
    initialState,
    (e) => onSubmit(e)
  );

  const onSubmit = async (data) => {
    try {
      const response = await authAxios.post(`/campaign`, {
        ...data,
      });
      const result = response.data;
      reset();
      toggle();
      retrieveData();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  return (
    <>
      <Button className="mb-1" color="primary" onClick={toggle}>
        Add
      </Button>

      <BootstrapModal isOpen={visible} toggle={toggle} size="xl">
        <BootstrapModal.Header>Add New Campaign</BootstrapModal.Header>
        <BootstrapModal.Body>
          <Form id="CampaignAdd" onSubmit={handleSubmit}>
            <CampaignForm formData={formData} handleOnChange={handleOnChange} />
          </Form>

          {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
        </BootstrapModal.Body>
        <BootstrapModal.Footer toggle={toggle}>
          <Button type="submit" form="CampaignAdd" color="success">
            Add
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

export { CampaignAdd };
