import React, { useContext } from "react";

import { FetchContext } from "context";
import { SimpleTable } from "components";
import { gradingColor } from "constants";

import { ContactInformation } from "features/common/Contact_Information/ContactInformation";
import { StageUpdateMain } from "./PipelineTracking/StageUpdate/StageUpdateMain";

const PipelineDisplay = () => {
  const { data, setData, error } = useContext(FetchContext);

  const handleTableUpdate = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const columns = [
    {
      Header: "Ranking",
      accessor: "ranking",
    },
    {
      Header: "Organization",
      accessor: "organization",
    },

    {
      Header: "Grade",
      accessor: "grading",
      disableFilters: true,
      Cell: ({ row }) => {
        const { grading } = row.original;
        return (
          <span className={`badge ${gradingColor[grading][0]}`}>
            {gradingColor[grading][1]}
          </span>
        );
      },
    },
    // {
    //   Header: "Industry",
    //   accessor: "industry",
    // },
    // {
    //   Header: "Vertical",
    //   accessor: "vertical",
    // },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: () => null,
      id: "contactInformation",
      width: 75,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <ContactInformation
              contact_id={original.contact_id}
              linked_in={original.linked_in}
            />
          </>
        );
      },
    },
    {
      Header: "Position",
      accessor: "position",
    },
    {
      Header: "Stage",
      accessor: "stage",

    },
    {
      Header: "",
      accessor: "update_stage",
      Cell: ({ row: { original } }) => {
        //   Cell: ({ row, column: { id }, updateMyData }) => {
        return (
          <>
            <StageUpdateMain id={original.id}></StageUpdateMain>
          </>
        );

        //   const { index } = row;
        //   let original = row.original;

        //   const handleUpdate = (data) => {
        //     updateMyData(index, id, data);
        //   };

        //   return (
        //     <DropDown
        //       data={original.next_action}
        //       onUpdateValues={handleUpdate}
        //       url={`/target/people/${original.target_contact_id}/next-action`}
        //       options={[
        //         { label: "-", value: "-" },
        //         { label: "Send Connection", value: "Send Connection" },
        //         {
        //           label: "Contact Other Stakeholders",
        //           value: "Contact Other Stakeholders",
        //         },
        //         { label: "Move to BD Pipeline", value: "Move to BD Pipeline" },
        //       ]}
        //     />
        //   );
      },
    },
  ];

  if (error) {
    return null;
  }

  return (
    <SimpleTable
      columns={columns}
      data={data}
      properties={{ height: 400 }}
      //    onHeaderClick={columnHeaderClick}
      updateMyData={handleTableUpdate}
    />
  );
};

export { PipelineDisplay };
