import React from "react";

import { Input } from "components";

const CampaignForm = ({ formData = {}, handleOnChange = () => null }) => {
  return (
    <>
      <Input
        label="Campaign Name:"
        type="text"
        value={formData.campaign_name}
        onChange={(e) => {
          handleOnChange("campaign_name", e.target.value);
        }}
      />
      <Input
        label="Campaign Description:"
        type="textarea"
        value={formData.campaign_description}
        onChange={(e) => {
          handleOnChange("campaign_description", e.target.value);
        }}
      />
    </>
  );
};

export { CampaignForm };
