import React from "react";

import { FetchProvider } from "context";
import { HRDirectorDisplay } from "./HRDirectorDisplay";
import { HRDirectorFilter } from "./HRDirectorFilter";

const HRDirectorMain = () => {
  return (
    <FetchProvider url={`/stakeholder/hr-director`}>
      <FetchProvider.LoaderOverlay />
      <FetchProvider.Error />

      <HRDirectorFilter />
      <HRDirectorDisplay />

      <FetchProvider.Pagination />
    </FetchProvider>
  );
};

export { HRDirectorMain };
