import React from "react";

import { FetchProvider } from "context";
import { TADirectorDisplay } from "./TADirectorDisplay";
import { TADirectorFilter } from "./TADirectorFilter";

const TADirectorMain = () => {
  return (
    <FetchProvider url={`/stakeholder/ta-director`}>
      <FetchProvider.LoaderOverlay />
      <FetchProvider.Error />

      <TADirectorFilter />
      <TADirectorDisplay />

      <FetchProvider.Pagination />
    </FetchProvider>
  );
};

export { TADirectorMain };
