import React, { useContext } from "react";

import { FetchContext } from "context";
import { SimpleTable } from "components";
import { gradingColor } from "constants";

import { CampaignHistoryMain } from "./CampaignHistory/CampaignHistoryMain";

const OrganizationDisplay = () => {
  const { data, error } = useContext(FetchContext);

  const columns = [
    {
      Header: "Ranking",
      accessor: "ranking",
    },
    {
      Header: "Company Type",
      accessor: "company_type",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Grade",
      accessor: "grading",
      disableFilters: true,
      Cell: ({ row }) => {
        const { grading } = row.original;
        return (
          <span className={`badge ${gradingColor[grading][0]}`}>
            {gradingColor[grading][1]}
          </span>
        );
      },
    },
    {
      Header: "Industry",
      accessor: "industry",
    },
    {
      Header: "Vertical",
      accessor: "vertical",
    },
    {
      Header: "Campaigns",
      id: "campaigns",
      disableFilters: true,
      Cell: ({ row }) => {
        const { target_organization_id } = row.original;
        return <CampaignHistoryMain organization_id={target_organization_id} />;
      },
    },
  ];

  if (error) {
    return null;
  }

  return (
    <SimpleTable columns={columns} data={data} properties={{ height: 400 }} />
  );
};

export { OrganizationDisplay };
