import React, { useContext } from "react";

import { FetchContext } from "context";
import { SimpleTable } from "components";
import { getFormattedDate } from "utils";
import { ContactInformation } from "features/common/Contact_Information/ContactInformation";

const VPDisplay = () => {
  const { data, sort, setSort } = useContext(FetchContext);

  const columnHeaderClick = (column) => {
    switch (column.sortDirection) {
      case "none":
        setSort({ direction: "ASC", accessor: column.id });
        break;
      case "ASC":
        setSort({ direction: "DESC", accessor: column.id });
        break;
      case "DESC":
        setSort({ direction: "none", accessor: column.id });
        break;
      default:
        return null;
    }
  };

  const columns = [
    {
      Header: "Organization",
      accessor: "organization",
    },
    {
      Header: "Name",
      accessor: "name",
      sortDirection: sort.accessor === "name" ? sort.direction : "none",
    },
    {
      Header: () => null,
      id: "contactInformation",
      width: 75,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <ContactInformation
              contact_id={original.contact_id}
              linked_in={original.linked_in}
            />
          </>
        );
      },
    },
    {
      Header: "Position",
      accessor: "position",
      sortDirection: sort.accessor === "position" ? sort.direction : "none",
    },
    {
      Header: "Date Started (C)",
      accessor: "date_started_company",
      Cell: ({ row: { original } }) => {
        let dt = original.date_started_company
          ? getFormattedDate({
              dateObject: original.date_started_company,
              format: "MMM YYYY",
            })
          : "-";
        return dt;
      },
      sortDirection:
        sort.accessor === "date_started_company" ? sort.direction : "none",
    },
    {
      Header: "Date Started (P)",
      accessor: "date_started_position",
      Cell: ({ row: { original } }) => {
        let dt = original.date_started_position
          ? getFormattedDate({
              dateObject: original.date_started_position,
              format: "MMM YYYY",
            })
          : "-";
        return dt;
      },
      sortDirection:
        sort.accessor === "date_started_position" ? sort.direction : "none",
    },
    {
      Header: "Industry",
      accessor: "industry",
      sortDirection: sort.accessor === "industry" ? sort.direction : "none",
    },
    {
      Header: "Vertical",
      accessor: "vertical",
      sortDirection: sort.accessor === "vertical" ? sort.direction : "none",
    },
    {
      Header: "Location",
      accessor: "location",
      sortDirection: sort.accessor === "location" ? sort.direction : "none",
    },
    {
      Header: "Connected?",
      accessor: "connected",
    },
    {
      Header: "New?",
      accessor: "new_to_position",
    },
  ];

  return (
    <SimpleTable
      columns={columns}
      data={data}
      properties={{ height: 400 }}
      onHeaderClick={columnHeaderClick}
    />
  );
};

export { VPDisplay };
