/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";

import { useLoading } from "./useLoading";
import { useAxiosPrivate } from "./useAxiosPrivate";

export const useFetch = ({
  initialUrl = "",
  initialParams = {},
  skip = false,
}) => {
  const controller = new AbortController();
  const authAxios = useAxiosPrivate();
  const [url, updateUrl] = useState(initialUrl);
  const [params, updateParams] = useState(initialParams);
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [refetchIndex, setRefetchIndex] = useState(0);

  const isFirstRun = useRef(skip);

  const queryString = Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

  const fetchData = async () => {
    try {
      const response = await authAxios.get(`${url}?${queryString}`, {
        signal: controller.signal,
      });
      const result = response.data;
      setData(result.content);
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const [getData, isLoading] = useLoading(fetchData);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    updateUrl(initialUrl);
    getData();

    return () => {
      controller.abort();
    };
  }, [url, params, refetchIndex, initialUrl]);

  const refetch = () => {
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);
  };

  return {
    data,
    setData,
    isLoading,
    errorMessage,
    updateUrl,
    updateParams,
    refetch,
  };
};
