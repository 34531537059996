import React from "react";

import { useFetch, useToggle } from "hooks";
import {
  BootstrapModal,
  Button,
  Conditional,
  LoadingOverlay,
} from "components";
import { CampaignHistoryDisplay } from "./CampaignHistoryDisplay";

const CampaignHistoryMain = ({ organization_id }) => {
  const { data, isLoading, errorMessage, refetch } = useFetch({
    initialUrl: `/target/organization/${organization_id}/campaign-history`,
    skip: true,
  });

  const [visible, toggle] = useToggle();

  const handleOpenModal = () => {
    toggle();
    refetch();
  };

  return (
    <>
      <Button color="primary" onClick={handleOpenModal}>
        View
      </Button>

      <BootstrapModal isOpen={visible} toggle={toggle} size="xl">
        <BootstrapModal.Header>Campaign History</BootstrapModal.Header>

        <BootstrapModal.Body>
          <LoadingOverlay active={isLoading}>Campaign History</LoadingOverlay>
          <Conditional if={errorMessage}>
            <h3>{errorMessage}</h3>
          </Conditional>

          <div style={{ textAlign: "center" }}>
            <CampaignHistoryDisplay data={data || []} />
          </div>
        </BootstrapModal.Body>

        <BootstrapModal.Footer toggle={toggle}></BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

export { CampaignHistoryMain };
