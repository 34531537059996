import React, { useContext } from "react";

import { FetchContext } from "context";
import { AnimatedSmartTable, SimpleTable } from "components";
import { gradingColor } from "constants";
import { getFormattedDate } from "utils";

import { DropDown, DatePicker } from "features/common/TableFetch";
import { ReachoutHistoryMain } from "./ReachoutHistory/ReachoutHistoryMain";
import { ContactInformation } from "features/common/Contact_Information/ContactInformation";
import { SelectColumnFilter } from "components/UI/Table/Addons";
const PeopleDisplay = () => {
  const { data, setData, error } = useContext(FetchContext);

  const handleTableUpdate = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const columns = [
    {
      Header: "Ranking",
      accessor: "ranking",
      Filter: SelectColumnFilter,
      filter: "equals",
    },
    {
      Header: "BD Letter",
      accessor: "bd_letter",
      Filter: SelectColumnFilter,
      filter: "equals",
      Cell: ({ row, column: { id }, updateMyData }) => {
        const { index } = row;
        let original = row.original;

        const handleUpdate = (data) => {
          updateMyData(index, id, data);
        };

        return (
          <DropDown
            data={original.bd_letter}
            onUpdateValues={handleUpdate}
            url={`/target/people/${original.target_contact_id}/bd-letter`}
            options={[
              { label: "Pending", value: "Pending" },
              { label: "Done", value: "Done" },
              { label: "Sent", value: " Sent" },
            ]}
          />
        );
      },
    },
    {
      Header: "Organization",
      accessor: "organization",
      Filter: SelectColumnFilter,
      filter: "equals",
    },
    {
      Header: "Grade",
      accessor: "grading",
      disableFilters: true,
      Cell: ({ row }) => {
        const { grading } = row.original;
        return (
          <span className={`badge ${gradingColor[grading][0]}`}>
            {gradingColor[grading][1]}
          </span>
        );
      },
    },
    {
      Header: "Org. Industry",
      accessor: "industry",
      disableFilters: true,

    },

    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: () => null,
      id: "contactInformation",
      width: 75,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <ContactInformation
              contact_id={original.contact_id}
              linked_in={original.linked_in}
            />
          </>
        );
      },
    },
    {
      Header: "Position",
      accessor: "position",
    },
    {
      Header: "New Connection",
      accessor: "new_connection",
      Cell: ({ row, column: { id }, updateMyData }) => {
        const { index } = row;
        let original = row.original;

        const handleUpdate = (data) => {
          updateMyData(index, id, data);
        };

        return (
          <DropDown
            data={original.new_connection}
            onUpdateValues={handleUpdate}
            url={`/target/people/${original.target_contact_id}/new-connection`}
            options={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
          />
        );
      },
    },
    // {
    //   Header: "Date Connected with",
    //   accessor: "date_connected",
    //   Cell: ({ row, column: { id }, updateMyData }) => {
    //     const { index } = row;
    //     let original = row.original;

    //     const handleUpdate = (data) => {
    //       updateMyData(index, id, data);
    //     };

    //     return (
    //       <DatePicker
    //         data={new Date(original.date_connected)}
    //         onUpdateValues={handleUpdate}
    //         url={`/target/people/${original.target_contact_id}/date-connected`}
    //       />
    //     );
    //   },
    // },
    {
      Header: "Latest Campaign Send Date",
      accessor: "latest_send",
      Cell: ({ row: { original } }) => {
        let dt = original.initial_send
          ? getFormattedDate({
            dateObject: new Date(original.initial_send),
            format: "MMM YYYY",
          })
          : "-";
        return dt;
      },
      disableFilters: true,

    },
    {
      Header: "Response Type",
      accessor: "response_type",
      Filter: SelectColumnFilter,
      filter: "equals",
    },
    // {
    //   Header: "No. of Follow up",
    //   accessor: "follow_up",
    // },
    {
      Header: "BD Campaign History",
      id: "reach_out_history",
      disableFilters: true,
      Cell: ({ row }) => {
        const { target_contact_id } = row.original;
        return <ReachoutHistoryMain contact_id={target_contact_id} />;
      },
    },
    {
      Header: "Next Action",
      accessor: "next_action",
      Filter: SelectColumnFilter,
      filter: "equals",
      Cell: ({ row, column: { id }, updateMyData }) => {
        const { index } = row;
        let original = row.original;

        const handleUpdate = (data) => {
          updateMyData(index, id, data);
        };

        return (
          <DropDown
            data={original.next_action}
            onUpdateValues={handleUpdate}
            url={`/target/people/${original.target_contact_id}/next-action`}
            options={[
              { label: "-", value: "-" },
              { label: "Send Connection", value: "Send Connection" },
              {
                label: "Contact Other Stakeholders",
                value: "Contact Other Stakeholders",
              },
              { label: "Move to BD Pipeline", value: "Move to BD Pipeline" },
            ]}
          />
        );
      },
    },
  ];

  if (error) {
    return null;
  }

  return (
    // <SimpleTable
    //   columns={columns}
    //   data={data}
    //   properties={{ height: 400 }}
    //   //    onHeaderClick={columnHeaderClick}
    //   updateMyData={handleTableUpdate}
    // />
    <AnimatedSmartTable
      data={data || []}
      columns={columns}
      properties={{ height: 400 }}
      overflow
    />
  );
};

export { PeopleDisplay };
