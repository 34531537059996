import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Spinner } from "reactstrap";

export const LoadingSpinner = ({ label, size }) => {
  return (
    <div className="my-5">
      <Row>
        <Col className="text-center">
          <Spinner
            style={{ width: "2rem", height: "2rem" }}
            color="dark"
            type="grow"
          />
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            color="dark"
            type="grow"
          />
          <Spinner
            style={{ width: "4rem", height: "4rem" }}
            color="dark"
            type="grow"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="d-flex justify-content-center">{label}</p>
        </Col>
      </Row>
    </div>
  );
};

LoadingSpinner.propTypes = {
  label: PropTypes.string,
  size: PropTypes.number,
};
