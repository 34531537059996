import React from "react";

import { FetchProvider } from "context";
import { CountryManagerDisplay } from "./CountryManagerDisplay";
import { CountryManagerFilter } from "./CountryManagerFilter";

const CountryManagerMain = () => {
  return (
    <FetchProvider url={`/stakeholder/country-manager`}>
      <FetchProvider.LoaderOverlay />
      <FetchProvider.Error />

      <CountryManagerFilter />
      <CountryManagerDisplay />

      <FetchProvider.Pagination />
    </FetchProvider>
  );
};

export { CountryManagerMain };
