import React from "react";

import { Form, FormGroup, Label } from "reactstrap";
import { toastr } from "react-redux-toastr";

import { BootstrapModal, Button, Input, SelectWrap } from "components";
import { useAxiosPrivate, useCustomForm, useToggle } from "hooks";
import ReactDatePicker from "react-datepicker";

const ReachtoutHistoryEdit = ({
  data,
  contact_id,
  history_id,
  onUpdateValue,
  onRefetch,
}) => {
  const initialState = {
    response_date: new Date(data?.response_date) || new Date(),
    response: data?.response || "",
    response_type: data?.response_type || "",
  };

  const [visible, toggle] = useToggle();
  const authAxios = useAxiosPrivate();

  const { formData, handleOnChange, handleSubmit } = useCustomForm(
    initialState,
    (e) => onSubmit(e)
  );

  const onSubmit = async (data) => {
    try {
      const response = await authAxios.put(
        `/target/people/${contact_id}/reach-out/${history_id}`,
        {
          ...data,
        }
      );
      const result = response.data;
      onUpdateValue(data);
      toggle();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  const onDelete = async () => {
    try {
      const response = await authAxios.delete(
        `/target/people/${contact_id}/reach-out/${history_id}`
      );
      const result = response.data;
      toggle();
      onRefetch();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  return (
    <>
      <Button color="primary" onClick={toggle}>
        Edit
      </Button>
      <Button className="ms-1" color="danger" onClick={onDelete}>
        Delete
      </Button>
      <BootstrapModal isOpen={visible} toggle={toggle} size="lg">
        <BootstrapModal.Header>Reach History Edit</BootstrapModal.Header>

        <BootstrapModal.Body>
          <Form id="ReachOutHistoryEdit" onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Date:</Label>
              <ReactDatePicker
                selected={formData.response_date}
                onChange={(event) => handleOnChange("response_date", event)}
                dateFormat="MM/dd/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </FormGroup>
            <Input
              label="Response:"
              type="textarea"
              value={formData.response}
              onChange={(e) => {
                handleOnChange("response", e.target.value);
              }}
            />
            <FormGroup>
              <SelectWrap
                name="grading"
                value={formData.response_type}
                options={[
                  { label: "Interested", value: "Interested" },
                  { label: "Not Interested", value: "Not Interested" },
                ]}
                onChange={(event) => handleOnChange("response_type", event)}
              />
            </FormGroup>
          </Form>
        </BootstrapModal.Body>

        <BootstrapModal.Footer toggle={toggle}>
          <Button type="submit" form="ReachOutHistoryEdit" color="success">
            Edit
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

export { ReachtoutHistoryEdit };
