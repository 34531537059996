/* eslint-disable array-callback-return */
import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { StageUpdateAdd } from "./StageUpdateAdd";
import { useAxiosPrivate, useToggle, useCustomForm, useFetch } from "hooks";
import { Button, SelectWrap } from "components";
import TimePicker from "react-time-picker";
import { stagesString } from "constants";
import ReactDatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";

const StageUpdateDisplay = ({ data, id, handleUpdate, onRefetch }) => {
  const authAxios = useAxiosPrivate();
  const [visible, toggle] = useToggle();

  const initialState = {
    date: new Date(),
    stage: "",
    time: new Date().toLocaleTimeString(),
    representative: "",
  };
  const { formData, handleOnChange, handleSubmit, reset } = useCustomForm(
    initialState,
    (e) => onSubmit(e)
  );
  const onSubmit = async (data) => {
    try {
      const response = await authAxios.post(
        `/target/pipeline/${id}/update-stage`,
        {
          ...data,
        }
      );
      const result = response.data;
      reset();
      // toggle();
      onRefetch();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  // const data_sample = [
  //   {
  //     stage: "Discovery Call",
  //     date: "14 AUG 2023",
  //     time: "2:30 PM",
  //     representative: "Mark Kearney",
  //   },
  //   {
  //     stage: "Meeting 1",
  //     date: "18 AUG 2023",
  //     time: "3:00 PM",
  //     representative: "Mark Kearney",
  //   },
  //   {
  //     stage: "Meeting 2",
  //     date: "21 AUG 2023",
  //     time: "8:00 AM",
  //     representative: "Mark Kearney",
  //   },
  // ];
  // //Not sure how we will handle "On-Boarding"

  // data = data_sample;
  return (
    <>
      <Row>
        <Col className="p-3">
          <div
            style={{
              padding: "1em",
              backgroundColor: "lightgrey",
              overflowY: "scroll",
              height: "30em",
            }}
          >
            {data?.length > 0 &&
              data?.map((data, key) => (
                <Card className="my-3 shadow p-3 mb-5 bg-body rounded">
                  <CardHeader>{data.stage}</CardHeader>
                  <CardBody>
                    <Table className="text-start">
                      <tbody>
                        <tr>
                          <td className="fw-bold">Date</td>
                          <td>{data.date}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Time</td>
                          <td>{data.time}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">ITEL Representative</td>
                          <td>{data.representative}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter className="text-end">
                    <Button color="danger">Delete</Button>
                  </CardFooter>
                </Card>
              ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <Button color="primary" onClick={toggle} className="my-3">
              Add
            </Button>
          </div>

          <Collapse isOpen={visible}>
            <Card>
              <CardBody className="text-start">
                <Form id="StageUpdateAdd" onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label className="fw-bold me-3">Date</Label>
                    <ReactDatePicker
                      selected={formData.date}
                      onChange={(event) => handleOnChange("date", event)}
                      dateFormat="MM/dd/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="fw-bold me-3">Meeting Time</Label>
                    <TimePicker
                      id="time"
                      name="time"
                      value={formData.time}
                      onChange={(event) => {
                        handleOnChange("time", event.target.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label> Stage </Label>
                    <SelectWrap
                      id="stage"
                      name="stage"
                      value={formData.stage || ""}
                      options={stagesString}
                      onChange={(event) => handleOnChange("stage", event)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Representative </Label>
                    <Input
                      id="representative"
                      type="text"
                      name="representative"
                      value={formData.representative || ""}
                      onChange={(event) =>
                        handleOnChange("representative", event.target.value)
                      }
                      required
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <Button type="submit" form="StageUpdateAdd" color="success">
                      Submit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </Col>
      </Row>
    </>

    // <AnimatedSmartTable
    //   data={data}
    //   columns={columns}
    //   updateMyData={handleUpdate}
    //   paginated
    // />
  );
};

export { StageUpdateDisplay };
