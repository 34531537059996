import "./styles/App.css";
import "./styles/ionicons.css";
import React from "react";

import { Switch, Route } from "react-router-dom";

import { Header, PrivateRoute, PersistLogin, PageTitle } from "./components";
import { AuthProvider } from "context";

import Login from "pages/Login";
import Unauthorized from "pages/Unauthorized";
import Home from "pages/Home";
import Targets from "pages/Targets";
import Organization from "pages/Organization";
import Campaign from "pages/Campaign";
import Stakeholders from "pages/Stakeholders";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Switch>
          <Route path="/login" render={() => <Login />} />
          <Route path="/unauthorized" render={() => <Unauthorized />} />

          <PersistLogin>
            <Header nav={privateRoutes} />
            <div
              className="container-fluid"
              style={{ paddingTop: "100px", paddingBottom: "100px" }}
            >
              {privateRoutes.map((data, index) => (
                <PrivateRoute
                  access_limit={data.access_limit}
                  key={index}
                  path={data.path}
                  exact={data.exact}
                >
                  <PageTitle title={data.label} />
                  {data.component}
                </PrivateRoute>
              ))}
            </div>
          </PersistLogin>
        </Switch>
      </AuthProvider>
    </div>
  );
}

const privateRoutes = [
  {
    exact: true,
    label: "Home",
    path: "/",
    component: <Home />,
    access_limit: [0, 1, 2, 3, 4, 5],
  },
  {
    label: "Organizations",
    path: "/organizations",
    component: <Organization />,
    access_limit: [0, 1, 2, 3, 4, 5],
  },
  {
    label: "Campaigns",
    path: "/campaigns",

    component: <Campaign />,
    access_limit: [0, 1, 2, 3, 4, 5],
  },
  {
    label: "Targets",
    path: "/targets",

    component: <Targets />,
    access_limit: [0, 1, 2, 3, 4, 5],
  },
  {
    label: "Stakeholders",
    path: "/stakeholders",

    component: <Stakeholders />,
    access_limit: [0, 1, 2, 3, 4, 5],
  },
];

export default App;
