import React from "react";

import {
  BootstrapModal,
  Button,
  Conditional,
  LoadingOverlay,
} from "components";
import { useFetch, useToggle } from "hooks";

import { ReachoutHistoryDisplay } from "./ReachoutHistoryDisplay";
import { ReachoutHistoryAdd } from "./ReachoutHistoryAdd";

const ReachoutHistoryMain = ({ contact_id }) => {
  const { data, setData, isLoading, errorMessage, refetch } = useFetch({
    initialUrl: `/target/people/${contact_id}/reach-out`,
    skip: true,
  });

  const [visible, toggle] = useToggle();

  const handleOpenModal = () => {
    toggle();
    refetch();
  };

  const handleUpdateTable = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <Button color="primary" onClick={handleOpenModal}>
        View
      </Button>

      <BootstrapModal isOpen={visible} toggle={toggle} size="xl">
        <BootstrapModal.Header>Reach Out History</BootstrapModal.Header>

        <BootstrapModal.Body>
          <LoadingOverlay active={isLoading}>Reach Out History</LoadingOverlay>
          <Conditional if={errorMessage}>
            <h3>{errorMessage}</h3>
          </Conditional>

          <div style={{ textAlign: "center" }}>
            {data ? (
              <ReachoutHistoryDisplay
                data={data}
                contact_id={contact_id}
                handleUpdate={handleUpdateTable}
                onRefetch={refetch}
              />
            ) : (
              <p>No data found..</p>
            )}
          </div>

          <ReachoutHistoryAdd contact_id={contact_id} onRefetch={refetch} />
        </BootstrapModal.Body>

        <BootstrapModal.Footer toggle={toggle}></BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

export { ReachoutHistoryMain };
