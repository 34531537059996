import React, { createContext, useState } from "react";

const AuthContext = createContext(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  return (
    <Provider
      value={{
        auth,
        setAuth,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
