import React from "react";

import ReactDatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";
import { Collapse, Card, CardBody, Form, FormGroup, Label } from "reactstrap";

import { useAxiosPrivate, useToggle, useCustomForm } from "hooks";
import { Button, AsyncSelectWrap } from "components";

const ReachoutHistoryAdd = ({ contact_id, onRefetch = () => {} }) => {
  const initialState = {
    date: new Date(),
    campaign_sent: "",
  };

  const authAxios = useAxiosPrivate();
  const [visible, toggle] = useToggle();

  const { formData, handleOnChange, handleSubmit, reset } = useCustomForm(
    initialState,
    (e) => onSubmit(e)
  );

  const onSubmit = async (data) => {
    try {
      const response = await authAxios.post(
        `/target/people/${contact_id}/reach-out`,
        {
          ...data,
        }
      );
      const result = response.data;
      reset();
      toggle();
      onRefetch();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-end">
        <Button color="primary" onClick={toggle}>
          Add
        </Button>
      </div>

      <Collapse isOpen={visible}>
        <Card>
          <CardBody>
            <Form id="ReachOutHistoryAdd" onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Date: </Label>
                <ReactDatePicker
                  selected={formData.date}
                  onChange={(event) => handleOnChange("date", event)}
                  dateFormat="MM/dd/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </FormGroup>

              <FormGroup>
                <Label>Campaign Sent: </Label>
                <AsyncSelectWrap
                  name="campaign_sent"
                  dependencies={{ url: "/parameters", topic: "campaign_sent" }}
                  value={formData.campaign_sent}
                  onChange={(event) => handleOnChange("campaign_sent", event)}
                  required
                />
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Button type="submit" form="ReachOutHistoryAdd" color="success">
                  Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};

export { ReachoutHistoryAdd };
