import "./select.scss";
import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

export const SelectWrap = (props) => {
  const { value, required, disabled, className } = props;
  let pardeValue = value;
  if (Array.isArray(value) || typeof value === "object")
    pardeValue = Object.keys(value).length > 0 ? value : "";

  return (
    <div className="select-wrapper-container">
      <Select
        {...props}
        className={`${className}`}
        classNamePrefix="select-wrapper"
      />
      <input
        className="input-required"
        type="text"
        defaultValue={pardeValue && JSON.stringify(pardeValue)}
        tabIndex={-1}
        autoComplete="off"
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

SelectWrap.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  autoFocus: PropTypes.bool,
};
