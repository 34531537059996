import React from "react";

import { Form } from "reactstrap";
import { toastr } from "react-redux-toastr";

import { BootstrapModal, Button } from "components";
import { useToggle, useCustomForm, useAxiosPrivate } from "hooks";

import { CampaignForm } from "./CampaignForm";

const CampaignEdit = ({
  id,
  data,
  onUpdateValue = () => {},
  retrieveData = () => {},
}) => {
  const authAxios = useAxiosPrivate();
  const initialState = {
    campaign_name: data?.campaign_name,
    campaign_description: data?.campaign_description,
  };

  const [visible, toggle] = useToggle();

  const { formData, handleOnChange, handleSubmit } = useCustomForm(
    initialState,
    (e) => onSubmit(e)
  );

  const onSubmit = async (data) => {
    try {
      const response = await authAxios.put(`/campaign/${id}`, {
        ...data,
      });
      const result = response.data;
      onUpdateValue(data);
      toggle();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  const handleOnDelete = async () => {
    try {
      const response = await authAxios.delete(`/campaign/${id}`);
      const result = response.data;
      retrieveData();
      toggle();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  return (
    <>
      <Button color="primary" onClick={toggle}>
        Info
      </Button>

      <BootstrapModal isOpen={visible} toggle={toggle} size="xl">
        <BootstrapModal.Header>Edit New Campaign</BootstrapModal.Header>
        <BootstrapModal.Body>
          <Form id="CampaignEdit" onSubmit={handleSubmit}>
            <CampaignForm formData={formData} handleOnChange={handleOnChange} />
          </Form>
          {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
        </BootstrapModal.Body>
        <BootstrapModal.Footer toggle={toggle}>
          <Button className="ms-2" color="danger" onClick={handleOnDelete}>
            Delete
          </Button>
          <Button type="submit" form="CampaignEdit" color="success">
            Edit
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

export { CampaignEdit };
