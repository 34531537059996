import React, { useContext, useMemo } from "react";

import { SimpleTable } from "components";
import { FetchContext } from "context";

const StakeholdersNotInAsia = () => {
  const { data, isLoading } = useContext(FetchContext);

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "InAsiaMainHeader",
        columns: [
          {
            Header: () => null,
            id: "InAsiaSubHeader",
            accessor: "label",
          },
        ],
      },
      {
        Header: "CRO",
        columns: [
          {
            Header: "Total Mapped",
            accessor: "cro_total",
            width: 250,
          },
          {
            Header: "Covered in Targets",
            accessor: "cro_covered",
            width: 250,
          },
        ],
      },
      {
        Header: "Global TA",
        columns: [
          {
            Header: "Total Mapped",
            accessor: "global_ta_total",
            width: 250,
          },
          {
            Header: "Covered in Targets",
            accessor: "global_ta_covered",
            width: 250,
          },
        ],
      },
      {
        Header: "CEO",
        columns: [
          {
            Header: "Total Mapped",
            accessor: "ceo_total",
            width: 250,
          },
          {
            Header: "Covered in Targets",
            accessor: "ceo_covered",
            width: 250,
          },
        ],
      },
    ],
    []
  );

  if (isLoading) {
    return null;
  }

  return (
    <SimpleTable
      columns={columns}
      data={data}
      properties={{ height: "auto" }}
    />
  );
};

export { StakeholdersNotInAsia };
