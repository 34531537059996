import React, { useContext, useState, useMemo } from "react";

import { Button, Row, Col } from "reactstrap";
import { toastr } from "react-redux-toastr";

import { FetchContext } from "context";
import { SimpleTable } from "components";
import { useAxiosPrivate } from "hooks";

import { GradingModal } from "../common/Grading_Modal/GradingModal";
import { ToggleCheckbox } from "../common/TableFetch";
import { HoverTooltip } from "components";
import { ContactInformation } from "../common/Contact_Information/ContactInformation";
import CurrencyFormat from "react-currency-format";
const OrganizationDisplay = () => {
  const authAxios = useAxiosPrivate();
  const [selectedRow, setSelectedRow] = useState([]);
  const {
    data,
    setData,
    sort,
    setSort,
    currentPage,
    setCurrentPage,
    retrieveData,
  } = useContext(FetchContext);

  const columnHeaderClick = (column) => {
    switch (column.sortDirection) {
      case "none":
        setSort({ direction: "ASC", accessor: column.id });
        break;
      case "ASC":
        setSort({ direction: "DESC", accessor: column.id });
        break;
      case "DESC":
        setSort({ direction: "none", accessor: column.id });
        break;
      default:
        return null;
    }
  };

  const onDelete = async () => {
    let listIDs = [];
    selectedRow.map((data) => {
      return listIDs.push({ id: data.organization_id });
    });

    try {
      const response = await authAxios.delete(`/organization`, {
        data: { id_arr: listIDs },
      });

      const result = response.data;
      if (currentPage > 1) {
        setCurrentPage(1);
      } else {
        retrieveData();
      }
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  const onUpdate = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Ranking",
        accessor: "ranking",
        sortDirection: sort.accessor === "ranking" ? sort.direction : "none",
      },
      {
        Header: "Company Type",
        accessor: "company_type",
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === 2 ? (
                <>
                  <HoverTooltip id={original.id} hoverText={"NO STAKEHOLDERS MAPPED!"} placement={"right"}>
                    <div className="text-danger">{original.name}
                    </div>
                  </HoverTooltip>
                </>


              ) : (original.name)}
            </>
          );
        },
        sortDirection: sort.accessor === "name" ? sort.direction : "none",
      },
      {
        Header: "Grade",
        accessor: "grading",
        Cell: ({ row, column: { id }, updateMyData }) => {
          const { index } = row;
          let original = row.original;

          const handleUpdate = (data) => {
            updateMyData(index, id, data);
          };
          return (
            <GradingModal
              grading={original.grading}
              onUpdateValues={handleUpdate}
              url={`/organization/${original.organization_id}/change-grading`}
            />
          );
        },
      },
      {
        Header: "VP Asia",
        accessor: "vp_asia",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status == 1 ? (
                <span className="text-warning">{original.vp_asia}</span>
              ) : (original.vp_asia)}
            </>
          );
        },
      },
      {
        Header: () => null,
        id: "contactInformation",
        width: 75,
        Cell: ({ row: { original } }) => {
          return (
            <>
              <ContactInformation
                contact_id={original.contact_id}
                linked_in={original.linked_in}
              />
            </>
          );
        },
      },
      {
        Header: "APAC Scale",
        accessor: "apac_team_size",
      },
      {
        Header: "Total Funding",
        accessor: "total_funding",
      },
      {
        Header: "Global Revenue (PREVIOUS YR)",
        accessor: "previous_year_revenue",
        Cell: ({ row: { original } }) => {
          return (
            <CurrencyFormat
              value={original.previous_year_revenue}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              renderText={value => <div>{value}</div>} />
          );
        },
      },
      {
        Header: "Global Revenue (CURRENT YR)",
        accessor: "current_year_revenue",
        Cell: ({ row: { original } }) => {
          return (
            <CurrencyFormat
              value={original.current_year_revenue}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              renderText={value => <div>{value}</div>} />
          );
        },
      },
      {
        Header: "Fiancial Year Start",
        accessor: "financial_year_start",
      },

    ],
    [sort.accessor, sort.direction]
  );

  return (
    <>
      <Row>
        <Col style={{ textAlign: "left" }}>
          <Button
            className="mb-3"
            color="danger"
            disabled={!selectedRow.length > 0}
            onClick={onDelete}
          >
            Delete
          </Button>
        </Col>
      </Row>
      <SimpleTable
        columns={columns}
        data={data}
        onSelectRows={setSelectedRow}
        properties={{ selectable: true }}
        onHeaderClick={columnHeaderClick}
        updateMyData={onUpdate}
      />
    </>
  );
};

export { OrganizationDisplay };
