import React from "react";

import { FetchProvider } from "context";
import { VPDisplay } from "./VPDisplay";
import { VPFilter } from "./VPFilter";

const VPMain = () => {
  return (
    <FetchProvider url={`/stakeholder/vp`}>
      <FetchProvider.LoaderOverlay />
      <FetchProvider.Error />

      <VPFilter />
      <VPDisplay />

      <FetchProvider.Pagination />
    </FetchProvider>
  );
};

export { VPMain };
