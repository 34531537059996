import React, { useContext } from "react";

import { LoadingSpinner, Conditional } from "components";
import { FetchContext } from "context";

export const LoaderSpinner = ({ title }) => {
  const { isLoading } = useContext(FetchContext);
  return (
    <Conditional if={isLoading}>
      <LoadingSpinner label={title} />
    </Conditional>
  );
};
