import React, { useContext } from "react";

import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "context";
export const PrivateRoute = ({ access_limit, children, ...rest }) => {
  const { auth } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        access_limit?.includes(auth?.access_limit) ? (
          children
        ) : auth.first_name ? (
          <Redirect
            to={{
              pathname: "/unauthorized",
              state: { from: location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
