import React from "react";

import { useHistory } from "react-router-dom";

import { PageTitle } from "components";

const Unauthorized = () => {
  const history = useHistory();

  const goBack = () => history.goBack();

  return (
    <>
      <PageTitle title={`Unauthorized`} />
      <section>
        <h1>Unauthorized</h1>
        <br />
        <p>You do not have access to the requested page.</p>
        <div>
          <button onClick={goBack}>Go Back</button>
        </div>
      </section>
    </>
  );
};

export default Unauthorized;
