import React from "react";

import PropTypes from "prop-types";
import { Input, Card, CardBody, Row, Col } from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";

import { CustomDatePicker, SelectWrap, AsyncSelectWrap } from "components";
import { InputType, exportFilterOption } from "constants";
export const ExportFilter = ({
  data = [],
  onAddFields = () => null,
  onRemoveFields = () => null,
  onChange = () => null,
  onTypeSelect = () => null,
}) => {
  return (
    <Card className="mt-4">
      <CardBody>
        <AnimatePresence>
          {data.map((inputField, index) => (
            <motion.div
              initial={{ x: 300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -300, opacity: 0 }}
              key={`${inputField}~${index}`}
            >
              <Row>
                <Col xs="6">
                  <SelectWrap
                    id={`select_type_${index}`}
                    name="inputType"
                    value={inputField.inputType || ""}
                    options={exportFilterOption}
                    onChange={(event) => onTypeSelect(index, event)}
                    required
                  />
                </Col>
                <Col key={inputField.inputType.value} xs="6">
                  <FilterInput
                    value={inputField.data}
                    dependencies={{
                      inputType: inputField.inputType.type,
                      topic: inputField.inputType.value,
                      index: index,
                    }}
                    handleOnChange={onChange}
                  />
                </Col>
              </Row>
              <div>
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => onRemoveFields(index)}
                >
                  -
                </button>
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => onAddFields()}
                >
                  +
                </button>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>

        {data.length === 0 && (
          <div>Click the Add Filter button to add a field</div>
        )}
      </CardBody>
    </Card>
  );
};

const FilterInput = ({
  value,
  dependencies: { inputType, topic, index },
  handleOnChange = () => null,
}) => {
  if (inputType === InputType.TEXT) {
    return (
      <Input
        type="text"
        name="data"
        maxLength={40}
        value={value}
        onChange={(event) => handleOnChange(index, "data", event.target.value)}
        placeholder="Place text here..."
        required
      />
    );
  } else if (inputType === InputType.DATE) {
    return (
      <CustomDatePicker
        selected={value || new Date()}
        onChange={(date) => {
          handleOnChange(index, "data", date);
        }}
        dateFormat="MMMM yyyy"
      />
    );
  } else if (inputType === InputType.NUMBER) {
    return (
      <Input
        type="number"
        name="data"
        value={value}
        onChange={(event) => handleOnChange(index, "data", event.target.value)}
        placeholder="Place number here..."
        required
      />
    );
  } else if (inputType === InputType.SINGLE) {
    return (
      <AsyncSelectWrap
        name="data"
        dependencies={{ url: "/parameters", topic: topic }}
        value={value}
        onChange={(event) => handleOnChange(index, "data", event)}
        required
      />
    );
  } else if (inputType === InputType.MULTI) {
    return (
      <AsyncSelectWrap
        name="data"
        dependencies={{ url: "/parameters", topic: topic }}
        value={value}
        onChange={(event) => handleOnChange(index, "data", event)}
        isMulti
        required
      />
    );
  } else {
    return null;
  }
};

ExportFilter.propTypes = {
  data: PropTypes.array.isRequired,
  onAddFields: PropTypes.func.isRequired,
  onRemoveFields: PropTypes.func.isRequired,
};
