import "./select.scss";
import React from "react";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

import { useAxiosPrivate } from "hooks";

export const AsyncSelectWrap = (props) => {
  const {
    value,
    required,
    disabled,
    className,
    defaultOptions = true,
    dependencies: { url, topic, id = "" },
  } = props;

  let pardeValue = value;
  if (Array.isArray(value) || typeof value === "object")
    pardeValue = Object.keys(value).length > 0 ? value : "";

  const authAxios = useAxiosPrivate();

  // Search API and load results in AsyncSelect
  const getAsyncOptions = async (inputText) => {
    const response = await authAxios.get(
      `${url}?topic=${topic}&value=${inputText}&id=${id}`
    );
    const result = response.data;
    return result.content;
  };

  const loadOptions = debounce((inputText, callback) => {
    getAsyncOptions(inputText).then((options) => callback(options));
  }, 500);

  return (
    <div className="select-wrapper-container">
      {authAxios && (
        <>
          <AsyncSelect
            {...props}
            className={`${className}`}
            classNamePrefix="select-wrapper"
            cacheOptions
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
          />
          <input
            className="input-required"
            type="text"
            defaultValue={pardeValue && JSON.stringify(pardeValue)}
            tabIndex={-1}
            autoComplete="off"
            required={required}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
};

AsyncSelectWrap.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  url: PropTypes.string,
  defaultOptions: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  autoFocus: PropTypes.bool,
};
