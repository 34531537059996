import React, { useState, useEffect } from "react";

import { toastr } from "react-redux-toastr";

import { Input } from "reactstrap";
import { useAxiosPrivate } from "hooks";

const DropDown = ({
  data,
  options = [],
  url,
  onUpdateValues = () => {},
}) => {
  const authAxios = useAxiosPrivate();
  const [userInput, setUserInput] = useState(data);

  useEffect(() => {
    setUserInput(data);
  }, [data]);

  const handleOnChange = async (event) => {
    try {
      const response = await authAxios.put(url, {
        value: event,
      });
      const result = response.data;
      setUserInput(event);
      onUpdateValues(event);
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  return (
    <Input
      type="select"
      value={userInput}
      onChange={(e) => {
        handleOnChange(e.target.value);
      }}
    >
      {options.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
  );
};

export { DropDown };
