import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input as InputStrap } from "reactstrap";

export const Input = (props) => {
  return (
    <FormGroup>
      <Label>{props.label}</Label>
      <InputStrap
        className={props.className}
        name={props.name}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        min={props.min}
        accept={props.accept}
        onClick={props.onClick}
        required
      />
    </FormGroup>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  rel: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accept: PropTypes.string,
  onClick: PropTypes.func,
};
