import React from "react";

import { toastr } from "react-redux-toastr";
import { motion } from "framer-motion";

import { BootstrapModal, SelectWrap } from "components";
import { useToggle, useAxiosPrivate } from "hooks";
import { gradingColor, gradingOption } from "constants";

const convertData = {
  1: ["gold"],
  2: ["silver"],
  3: ["bronze"],
  4: ["rejected"],
  5: ["pending"],
};

export const GradingModal = ({ grading, url, onUpdateValues = () => {} }) => {
  const authAxios = useAxiosPrivate();
  const [visible, toggle] = useToggle();

  const handleOnChange = async (event) => {
    try {
      const response = await authAxios.post(url, {
        grading: event.value,
      });
      const result = response.data;
      onUpdateValues(convertData[event.value][0]);
      toggle();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  return (
    <>
      <motion.div whileHover={{ scale: 1.1 }}>
        <span
          style={{ cursor: "pointer" }}
          onClick={toggle}
          className={`badge ${gradingColor[grading][0]}`}
        >
          {gradingColor[grading][1]}
        </span>
      </motion.div>
      <BootstrapModal size="sm" isOpen={visible} toggle={toggle}>
        <BootstrapModal.Header>Change Grading</BootstrapModal.Header>
        <BootstrapModal.Body>
          <SelectWrap
            name="grading"
            value={{ label: grading, value: grading }}
            options={gradingOption}
            onChange={(event) => handleOnChange(event)}
            isSearchable
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer toggle={toggle}></BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};
