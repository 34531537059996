import React from "react";
import { Button as BootstrapButton } from "reactstrap";
import PropTypes from "prop-types";

const Button = (props) => {
  const { children } = props;

  return <BootstrapButton {...props}>{children} </BootstrapButton>;
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  outline: PropTypes.bool,
  active: PropTypes.bool,
};

export { Button };
