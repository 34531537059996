import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
  NavbarToggler,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // UncontrolledDropdown,
  Collapse,
} from "reactstrap";

import { useToggle, useLogout } from "hooks";
import { AuthContext } from "context";

export const Header = ({ nav = [] }) => {
  const { auth } = useContext(AuthContext);
  const logout = useLogout();
  const [isOpen, setIsOpen] = useToggle();
  const [visible, toggle] = useToggle();

  return (
    <>
      <Navbar
        color="light"
        light
        className="shadow-sm p-3 mb-5 bg-white rounded"
        expand="md"
        fixed="top"
      >
        <NavbarBrand className="text-primary display-1 brand">
          ITEL BD Platform
        </NavbarBrand>
        <NavbarToggler onClick={setIsOpen} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {nav?.map((data, index) => (
              <NavItem className="fw-bold" key={index}>
                <NavLink tag={Link} to={data.path}>
                  {data.label}
                </NavLink>
              </NavItem>
            ))}

            {/* {auth?.access_limit === 0 && (
              <NavItem className="fw-bold">
                <NavLink tag={Link} to="/users">
                  Users
                </NavLink>
              </NavItem>
            )} */}
          </Nav>
          <Dropdown isOpen={visible} toggle={toggle}>
            <DropdownToggle
              outline
              caret
            >{`${auth?.first_name} ${auth?.last_name}`}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={logout}>Logout</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Collapse>
      </Navbar>
    </>
  );
};
