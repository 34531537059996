/* eslint-disable array-callback-return */
import React from "react";

import { getFormattedDate } from "utils";
import { AnimatedSmartTable } from "components";
import { ReachtoutHistoryEdit } from "./ReachtoutHistoryEdit";

const ReachoutHistoryDisplay = ({
  data = [],
  contact_id,
  onRefetch = () => null,
  handleUpdate = () => null,
}) => {
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      disableFilters: true,
      Cell: ({ row: { original } }) => {
        let dt = original.date
          ? getFormattedDate({
              dateObject: original.date,
              format: "DD MMM YYYY",
            })
          : "-";
        return dt;
      },
    },
    {
      Header: "Campaign",
      accessor: "campaign",
      disableFilters: true,
    },
    {
      Header: "Response",
      accessor: "response",
      disableFilters: true,
    },
    {
      Header: "Response Type",
      accessor: "response_type.value",
      disableFilters: true,
    },
    {
      Header: "Response Date",
      accessor: "response_date",
      disableFilters: true,
      Cell: ({ row: { original } }) => {
        let dt = original.response_date
          ? getFormattedDate({
              dateObject: original.response_date,
              format: "DD MMM YYYY",
            })
          : "-";
        return dt;
      },
    },
    {
      Header: () => null,
      id: "edit",
      disableFilters: true,
      Cell: ({ row, updateMyData }) => {
        const { index } = row;
        let original = row.original;

        const proccessKeys = (data) => {
          Object.keys(data).map((key) => {
            updateMyData(index, key, data[key]);
          });
        };

        return (
          <ReachtoutHistoryEdit
            data={original}
            contact_id={contact_id}
            history_id={original.campaign_history_id}
            onUpdateValue={proccessKeys}
            onRefetch={onRefetch}
          />
        );
      },
    },
  ];

  return (
    <AnimatedSmartTable
      data={data}
      columns={columns}
      updateMyData={handleUpdate}
      paginated
    />
  );
};

export { ReachoutHistoryDisplay };
