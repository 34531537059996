import React from "react";

import { FetchProvider } from "context";
import { RegionalLeaderDisplay } from "./RegionalLeaderDisplay";
import { RegionalLeaderFilter } from "./RegionalLeaderFilter";

const RegionalLeaderMain = () => {
  return (
    <FetchProvider url={`/stakeholder/regional-leader`}>
      <FetchProvider.LoaderOverlay />
      <FetchProvider.Error />

      <RegionalLeaderFilter />
      <RegionalLeaderDisplay />

      <FetchProvider.Pagination />
    </FetchProvider>
  );
};

export { RegionalLeaderMain };
