/* eslint-disable array-callback-return */
import React, { useContext } from "react";

import { Card, CardBody, Row, Col } from "reactstrap";
import { FetchContext } from "context";

import { CampaignEdit } from "./CampaignEdit";

const CampaignDisplay = () => {
  const { data, setData, retrieveData } = useContext(FetchContext);

  const handleTableUpdate = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const processKey = (index, data) => {
    Object.keys(data).map((key) => {
      handleTableUpdate(index, key, data[key]);
    });
  };

  return (
    <>
      {data?.length > 0 &&
        data.map((data_, index) => (
          <Card key={data_.campaign_id} className="mb-3">
            <CardBody>
              <Row>
                <Col className="d-flex justify-content-start">
                  <h3>{data_.campaign_name}</h3>
                </Col>
                <Col className="d-flex justify-content-end">
                  <CampaignEdit
                    id={data_.campaign_id}
                    data={data_}
                    onUpdateValue={(e) => processKey(index, e)}
                    retrieveData={retrieveData}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
    </>
  );
};

export { CampaignDisplay };
