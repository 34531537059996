import React, { useContext } from "react";

import {
  Card,
  Form,
  Input,
  FormGroup,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import { FetchContext } from "context";
import { AsyncSelectWrap } from "components";

const TADirectorFilter = () => {
  const { retrieveData, setCurrentPage, search, setSearch } =
    useContext(FetchContext);
  const handleOnChange = (name, value) => {
    setSearch({
      ...search,
      [name]: value ? value : undefined,
    });
  };

  const handleOnSearch = (event) => {
    event.preventDefault();
    setCurrentPage(1);
    retrieveData();
  };

  return (
    <Card className=" p-3 mb-3  shadow-sm rounded">
      <h2 style={{ textAlign: "left" }}>Filters</h2>
      <Form>
        <Row style={{ textAlign: "left" }}>
          <Col>
            <FormGroup>
              <Label for="Filter-Name">Name:</Label>
              <Input
                id="Filter-Ranking"
                placeholder="Search Name"
                type="text"
                value={search.name || ""}
                onChange={(e) => handleOnChange("name", e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="Filter-Industry">Industry:</Label>
              <AsyncSelectWrap
                name="industry"
                dependencies={{ url: "/parameters", topic: "industry" }}
                value={search.industry}
                onChange={(event) => handleOnChange("industry", event)}
                isClearable
              />
            </FormGroup>
            <FormGroup>
              <Label for="Filter-Organization">Vertical:</Label>
              <AsyncSelectWrap
                name="vertical"
                dependencies={{ url: "/parameters", topic: "vertical" }}
                value={search.vertical}
                onChange={(event) => handleOnChange("vertical", event)}
                isClearable
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label for="Filter-Industry">Location:</Label>
              <Input
                id="Filter-Ranking"
                placeholder="Search Location"
                type="text"
                value={search.location || ""}
                onChange={(e) => handleOnChange("location", e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button color="primary" type="submit" onClick={handleOnSearch}>
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { TADirectorFilter };
