import React, { useContext, useMemo } from "react";

import { SimpleTable } from "components";
import { FetchContext } from "context";

const TargetBreakDown = () => {
  const { data, isLoading } = useContext(FetchContext);

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "targetBreakdown",
        accessor: "label",
      },
      {
        Header: "Gold",
        accessor: "gold",
      },
      {
        Header: "Silver",
        accessor: "silver",
      },
      {
        Header: "Bronze",
        accessor: "bronze",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],
    []
  );

  if (isLoading) {
    return null;
  }

  return (
    <SimpleTable columns={columns} data={data} properties={{ height: "auto" }} />
  );
};

export { TargetBreakDown };
