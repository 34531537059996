import React, { useContext } from "react";

import { toastr } from "react-redux-toastr";

import { FetchContext } from "context";
import { Button } from "components";
import { useAxiosPrivate, useLoading } from "hooks";

const SyncData = () => {
  const authAxios = useAxiosPrivate();
  const { retrieveData } = useContext(FetchContext);

  const fetchItapDB = async () => {
    try {
      const response = await authAxios.post(`/organization/sync-data`);
      const result = response.data;
      retrieveData();
      toastr.success("Success", result.message);
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  const [onRefresh, isLoading] = useLoading(fetchItapDB);

  return (
    <Button color="primary" disabled={isLoading} onClick={onRefresh}>
      {isLoading ? "Processing" : "Refresh"}
    </Button>
  );
};

export { SyncData };
