import React from "react";

import {
  Badge,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  CardHeader,
  Label,
} from "reactstrap";

export const BasicInformation = ({ data, linked_in }) => {
  const basicInformation = [
    { label: "Gender", value: data?.gender },
    { label: "Age", value: data?.year_graduated },
    {
      label: "Organization",
      value: (
        <a
          href={`https://www.itap-platform.com/organizations/${data?.organization_id}/${data?.organization}/details`}
          target="_blank"
          rel="noreferrer"
        >
          {data?.organization}
        </a>
      ),
    },
    { label: "Job Title", value: data?.job_title },
    {
      label: "Current Position Time Frame",
      value: data?.time_frame,
    },
    {
      label: "Current Company Time Frame",
      value: data?.time_frame_company,
    },
    { label: "ID Status", value: data?.id_status },
    { label: "Nationality", value: data?.nationality },
    { label: "Personal Email", value: data?.personal_email },
    { label: "Company Email", value: data?.work_email },
    { label: "Mobile", value: data?.mobile_number },
    { label: "Industry", value: data?.industry },
    { label: "Vertical", value: data?.vertical },
    { label: "Seniority", value: data?.seniority },
    { label: "Position", value: data?.position },
    { label: "Location", value: data?.location },
    { label: "Geography", value: data?.geography },
  ];

  const previousStatus = [
    { label: "Previous Job", value: data?.previous_job },
    {
      label: "Previous Organization",
      value: data?.previous_organization,
    },
    { label: "Previous title", value: data?.previous_title },
    { label: "Previous Time Frame", value: data?.time_frame },
    {
      label: "Previous Company Time Frame",
      value: data?.time_frame_company,
    },
  ];

  const basicSalary = [
    { label: "Base:", value: data?.base_salary },
    { label: "Commission:", value: data?.base_salary },
    { label: data?.salary_incentives, value: null },
    { label: "Split:", value: data?.salary_split },
  ];

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <p>
          <small>Last updated: {data?.last_updated}</small>
        </p>
        <p>
          <small>Date Added: {data?.date_added}</small>
        </p>
      </div>
      <div className="text-center">
        <div className="mg-b-10">
          <h2>{data?.name}</h2>
          <a href={linked_in} target="_blank" rel="noreferrer">
            {linked_in}
          </a>
        </div>
      </div>
      <Row className="mt-3">
        <Col className="lg-6 md-6">
          <Card>
            <ListGroup flush>
              <ListGroupItem>
                {basicInformation.map((data, index) => (
                  <p key={index} className="contact-item border-bottom">
                    <span>{data.label}</span>
                    <span className="tx-inverse">{data.value}</span>
                  </p>
                ))}
                <p className="contact-item border-bottom">
                  <span></span>
                  <span></span>
                </p>
                {previousStatus.map((data, index) => (
                  <p key={index} className="contact-item border-bottom">
                    <span>{data.label}</span>
                    <span className="tx-inverse">{data.value}</span>
                  </p>
                ))}
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
        <Col className="lg-6 md-6">
          <Card>
            <ListGroup flush>
              <ListGroupItem>
                <Label className="tx-danger">Basic Salary Details</Label>
                <p className="contact-item">
                  <span>Currency:</span>
                  <span className="tx-inverse">{data?.salary_currency}</span>
                </p>
                {basicSalary.map((data, index) => (
                  <p key={index} className="contact-item border-bottom">
                    <span>{data.label}</span>
                    <span className="tx-inverse">{data.value}</span>
                  </p>
                ))}
              </ListGroupItem>
            </ListGroup>
          </Card>
          <Card className="mg-t-10 mt-3">
            <CardHeader>
              <span>Connections</span>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem>
                {data?.connections?.length > 0 &&
                  data?.connections.map((data, index) => (
                    <Badge className="me-2" color="info" key={index}>
                      {data.connection}
                    </Badge>
                  ))}
              </ListGroupItem>
            </ListGroup>
          </Card>
          <Card className="mg-t-10 mt-3">
            <CardHeader>
              <span>Sales Specializations</span>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem>
                {data?.sales_specialization?.length > 0 &&
                  data?.sales_specialization.map((data, index) => (
                    <Badge className="me-2" color="info" key={index}>
                      {data.specialization}
                    </Badge>
                  ))}
              </ListGroupItem>
            </ListGroup>
          </Card>
          <Card className="mg-t-10 mt-3">
            <CardHeader>
              <span>Software Development Specializations</span>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem>
                {data?.dev_specialization?.length > 0 &&
                  data?.dev_specialization.map((data, index) => (
                    <Badge className="me-2" color="info" key={index}>
                      {data.dev_specializations}
                    </Badge>
                  ))}
              </ListGroupItem>
            </ListGroup>
          </Card>
          <Card className="mg-t-10 mt-3">
            <CardHeader>
              <span>Languages</span>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem>
                {data?.languages?.length > 0 &&
                  data?.languages.map((data, index) => (
                    <Badge className="me-2" color="info" key={index}>
                      {data.languages}
                    </Badge>
                  ))}
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};
