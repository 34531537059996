/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-key */
import React from "react";

import { usePagination, DOTS } from "hooks";

export const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 3,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const onSelectPage = (e) => {
    onPageChange(parseInt(e.target.value, 10));
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="mt-2">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 && "disabled"} `}>
          <a onClick={onPrevious} className="page-link">
            {"<"}
          </a>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} className="page-link">
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={index}
              className={`page-item ${pageNumber === currentPage && "active"}`}
              onClick={() => onPageChange(pageNumber)}
            >
              <a className="page-link">{pageNumber}</a>
            </li>
          );
        })}
        <li className={`page-item ${currentPage === lastPage && "disabled"} `}>
          <a onClick={onNext} className="page-link">
            {">"}
          </a>
        </li>
      </ul>

      <select className="me-2" onChange={onSelectPage} value={currentPage}>
        {paginationRange.map((pageNumber, index) => (
          <option key={index} value={pageNumber}>
            {pageNumber}
          </option>
        ))}
      </select>

      <span>
        Page:{" "}
        <span>
          {currentPage} / {lastPage}
        </span>{" "}
        of {totalCount} items
      </span>
    </div>
  );
};
