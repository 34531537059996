import { useState, useEffect } from "react";

export const useCustomForm = (
  initialState = {},
  onSubmit = () => null,
  validate
) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      onSubmit?.(formData);
      setIsSubmitting(false);
    }
  }, [errors, formData, isSubmitting, onSubmit]);

  const handleOnChange = (name, event) => {
    if (event?.target?.type === "checkbox") {
      setFormData({
        ...formData,
        [name]: event.target.checked,
      });
      return;
    }

    setFormData({
      ...formData,
      [name]: event ? event : "",
    });
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    if (validate) {
      setErrors(validate(formData));
    }
    setIsSubmitting(true);
  };

  const reset = () => {
    setFormData(initialState);
  };

  return { formData, errors, reset, handleOnChange, handleSubmit };
};
