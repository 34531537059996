import React from "react";

import { FetchProvider } from "context";

import { PeopleDisplay } from "./PeopleDisplay";

const PeopleMain = () => {
  return (
    <>
      <div className="mb-5">
        <FetchProvider url={`/target/people`}>
          <h2 className="d-flex justify-content-start">BD Send Out</h2>
          <FetchProvider.LoaderOverlay />
          <FetchProvider.Error />

          <PeopleDisplay />

          <FetchProvider.Pagination />
        </FetchProvider>
      </div>
      <div className="mb-5">
        <FetchProvider url={`/target/people/vp-send-out`}>
          <h2 className="d-flex justify-content-start">VP Send Out</h2>
          <FetchProvider.LoaderOverlay />
          <FetchProvider.Error />

          <PeopleDisplay />

          <FetchProvider.Pagination />
        </FetchProvider>
      </div>
    </>
  );
};

export { PeopleMain };
