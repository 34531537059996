import React from "react";

import PropTypes from "prop-types";
import { ModalBody } from "reactstrap";

export const Body = ({ style, children }) => {
  return <ModalBody style={style}>{children}</ModalBody>;
};

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
