import React from "react";

import { FetchProvider } from "context";

import { PipelineDisplay } from "./PipelineDisplay";
const PipelineMain = () => {
  return (
    <>
      <div className="mb-5">
        <FetchProvider url={`/target/pipeline`}>
          <h2 className="d-flex justify-content-start">Pipeline</h2>
          <FetchProvider.LoaderOverlay />
          <FetchProvider.Error />

          <PipelineDisplay />

          <FetchProvider.Pagination />
        </FetchProvider>
      </div>
      {/* <div className="mb-5">
        <FetchProvider url={`/target/people/vp-send-out`}>
          <h2 className="d-flex justify-content-start">VP Send Out</h2>
          <FetchProvider.LoaderOverlay />
          <FetchProvider.Error />

          <PipelineDisplay />

          <FetchProvider.Pagination />
        </FetchProvider>
      </div> */}
    </>
  );
};

export { PipelineMain };
