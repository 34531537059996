import React from "react";

import { Row, Col, ButtonGroup } from "reactstrap";

import { FetchProvider } from "context";
import {
  OrganizationFilter,
  OrganizationDisplay,
  SyncData,
  ExportOrg,
} from "features/Organizations";

const Organization = () => {
  return (
    <FetchProvider url={`/organization`}>
      <Row>
        <Col lg="auto" className="d-flex justify-content-start">
          <h1>Organization</h1>
        </Col>
        <Col className="mb-2 d-flex justify-content-end">
          <ButtonGroup vertical>
            <SyncData />
            <ExportOrg />
          </ButtonGroup>
        </Col>
      </Row>

      <FetchProvider.LoaderOverlay />
      <FetchProvider.Error />

      <OrganizationFilter />
      <OrganizationDisplay />

      <FetchProvider.Pagination />
    </FetchProvider>
  );
};

export default Organization;
