import "../styles/Login.css";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Label, Input, Form, FormGroup, Card } from "reactstrap";

import { publicAxios } from "api/axios";
import { AuthContext } from "context";
import { PageTitle } from "components";

const Login = () => {
  const history = useHistory();
  const { setAuth } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email_address: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await publicAxios.post(
        "/login",
        {
          email_address: formData.email_address,
          password: formData.password,
        },
        { withCredentials: true }
      );
      const result = response.data;
      setAuth(result);
      history.push("/");
    } catch (error) {
      if (error.response) {
        const { msg } = error.response.data;
        setError(msg);
      }
    }
  };

  const handleOnChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value ? value : "",
    });
  };

  return (
    <>
      <PageTitle title={`Login`} />
      {/* <div
        style={{
          backgroundImage: `url(${"/assets/login-bg.jpg"})`,
          backgroundRepeat: "no-repeat",
          width: "250px",
        }}
      ></div> */}
      <div
        style={{
          backgroundImage: `url(${"/assets/login-bg.jpg"})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <Card
          className="login-card d-flex align-items-center"
          style={{
            backgroundColor: "rgba(250, 250, 250, 0.5)",
          }}
        >
          <h1 className="text-primary fw-bold">BD App</h1>
          <h5 className="mt-3 fw-bold">Welcome, please sign in!</h5>
          <Form
            className="form"
            id="BasicInformationEdit"
            onSubmit={handleOnSubmit}
          >
            {error && <Alert color="danger">{error}</Alert>}
            <FormGroup>
              <Label for="exampleEmail">Email:</Label>
              <Input
                id="exampleEmail"
                type="email"
                maxLength={40}
                value={formData.email_address}
                onChange={(e) => {
                  handleOnChange("email_address", e.target.value);
                }}
                placeholder="your_email@itel-international.com"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Password:</Label>
              <Input
                id="examplePassword"
                type="password"
                maxLength={40}
                value={formData.password}
                onChange={(e) => {
                  handleOnChange("password", e.target.value);
                }}
                required
              />
            </FormGroup>
            <Button color="primary">Submit</Button>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Login;
