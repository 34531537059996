import React, { useContext } from "react";

import { LoadingOverlay } from "components";
import { FetchContext } from "context";

export const LoaderOverlay = ({ title }) => {
  const { isLoading } = useContext(FetchContext);

  return <LoadingOverlay active={isLoading}>{title}</LoadingOverlay>;
};
