import React from "react";

import { useRouteMatch, NavLink, Switch, Route } from "react-router-dom";

import { PageTitle } from "components";
import {
  VPMain,
  RegionalLeaderMain,
  CountryManagerMain,
  TADirectorMain,
  HRDirectorMain,
} from "features/Stakeholders";

const Stakeholders = () => {
  const { url, path } = useRouteMatch();

  const routes = [
    {
      exact: true,
      label: "VP",
      to: `${url}`,
      path: `${path}`,
      Component: VPMain,
    },
    {
      label: "Regional Leader",
      to: `${url}/regional-leader`,
      path: `${path}/regional-leader`,
      Component: RegionalLeaderMain,
    },
    {
      label: "Country Manager",
      to: `${url}/country-manager`,
      path: `${path}/country-manager`,
      Component: CountryManagerMain,
    },
    {
      label: "TA Director",
      to: `${url}/ta-director`,
      path: `${path}/ta-director`,
      Component: TADirectorMain,
    },
    {
      label: "HR Director",
      to: `${url}/hr-director`,
      path: `${path}/hr-director`,
      Component: HRDirectorMain,
    },
  ];

  return (
    <>
      <h1 className="d-flex justify-content-start">Stakeholders</h1>

      <div className="mb-3">
        <ul className="nav justify-content-center nav-tabs">
          {routes.map(({ to, label }) => (
            <li className="nav-item" key={to}>
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={to}
              >
                {label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      <Switch>
        {routes.map(({ exact, path, label, Component }) => (
          <Route exact={exact} path={path} key={path}>
            <PageTitle title={`${label} - Targets`} />
            <Component />
          </Route>
        ))}
      </Switch>
    </>
  );
};

export default Stakeholders;
