import React from "react";

import { FetchProvider } from "context";

import {
  TargetBreakDown,
  StakeholdersTotal,
  StakeholdersInAsia,
  StakeholdersNotInAsia,
} from "features/Dashboard";

const Home = () => {
  return (
    <>
      <FetchProvider url={`/dashboard/target`}>
        <h3 className="d-flex justify-content-start">
          Target Companies Break Down
        </h3>

        <FetchProvider.LoaderSpinner />
        <FetchProvider.Error />

        <TargetBreakDown />
      </FetchProvider>

      <FetchProvider url={`/dashboard/stakeholders/total`}>
        <h3 className="d-flex justify-content-start">Stakeholders - Summary</h3>

        <FetchProvider.LoaderSpinner />
        <FetchProvider.Error />

        <StakeholdersTotal />
      </FetchProvider>

      <FetchProvider url={`/dashboard/stakeholders/in-asia`}>
        <h3 className="d-flex justify-content-start">
          In Asia Stakeholders per GSB
        </h3>

        <FetchProvider.LoaderSpinner />
        <FetchProvider.Error />

        <StakeholdersInAsia />
      </FetchProvider>

      <FetchProvider url={`/dashboard/stakeholders/not-in-asia`}>
        <h3 className="d-flex justify-content-start">
          Coming to Asia Stakeholders per GSB
        </h3>

        <FetchProvider.LoaderSpinner />
        <FetchProvider.Error />

        <StakeholdersNotInAsia />
      </FetchProvider>
    </>
  );
};

export default Home;
